import 'sidr/dist/jquery.sidr.js';
import $ from 'jquery';

import './style.scss';

$(document).on('content:loaded', function(event) {
  $(event.target).find('#page-navbar .navbar-toggler').sidr({
    displace: false,
    renaming: false,
    side: 'right',
    onOpen() {
      $('html').css({
        overflow: 'hidden'
      });
    },

    onClose() {
      $('html').css({
        overflow: ''
      });
    }
  });

  $(event.target).find('#overlay').click(function() {
    $.sidr('close', 'sidr');
  })
});
