import $ from 'jquery';
import _ from 'lodash';

import 'cascading-dropdown/src/jquery.cascading-drop-down.js';

const initCascadingDropDown = function(root) {
  $(root).find('.cascadingDropDown').ssdCascadingDropDown({
    startCall(trigger, props) {
      const items = $(`[data-group="${props.group}"]`).not(':disabled');
      const nameMapping = _.reduce(items, function(result, item) {
        result[$(item).prop('name')] = $(item).data('param-name');
        return result;
      }, {});

      _.each(props.selection, function(selection) {
        selection.name = nameMapping[selection.name] || selection.name;
      });
    }
  });
};

$(document).on('content:loaded', function(event) {
  initCascadingDropDown(event.target);
});
