import './style.scss';

import $ from 'jquery';

import 'slick-carousel/slick/slick.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const initSchoolbooksBlock = function(root) {
  $(root).find('.books-selector .slick').slick({
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 5,
    speed: 500,
    swipeToSlide: true,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: '110px',
          slidesToShow: 1,
          arrows: false
        }
      },
    ]
  });
};

$(document).on('content:loaded', function(event) {
  initSchoolbooksBlock(event.target);
});

$(document).on('turbolinks:before-cache', function(event) {
  $(event.target).find('.books-selector .slick').slick('unslick');
});
