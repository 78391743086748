import $ from 'jquery';

import Turbolinks from 'turbolinks';

$(document).on('turbolinks:load', function(event) {
  const $element = $(event.target);
  $element.trigger('content:loading');
  $element.trigger('content:loaded');
  setTimeout(function() {
    $element.trigger('content:ready');
  }, 0)
});

$(document).on('turbolinks:click', function(event) {
  const $target = $(event.target)
  if ($target.attr('href').charAt(0) === '#') {
    event.preventDefault()
  }
})

if (Turbolinks.supported) {
  Turbolinks.start()
} else {
  $(function() {
    $(document).trigger('turbolinks:load')
  })
}
