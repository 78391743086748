import $ from 'jquery';
import _ from 'lodash';

const findCaptchaModal = function() {
  return {
    element: $(document).find('#captcha-modal'),
    hide() {
      this.element.modal('hide');
    },
    show(html, callback) {
      this.element.html(html);
      this.element.modal('show');

      this.element.find('form').on('submit', function(event) {
        event.preventDefault();

        const data = $(this).find('.form-group input').serializeArray();
        callback(data);
      });
    }
  };
};

const requestWithCaptcha = function(request) {
  const modal = findCaptchaModal();

  request.handleError = function(xhr) {
    if (xhr.status !== 412) {
      return false;
    }

    modal.show(xhr.responseText, (fields) => {
      const newData = _.filter(this.data, function(item) {
        return !_.some(fields, { name: item.name });
      }).concat(fields);

      this.action(newData);
    });

    return true;
  };

  request.onSuccess = function() {
    modal.hide();
  };

  request.action();
};

/* eslint-disable no-alert */
$(function() {
  $(document).on('click', '.resend', function(event) {
    event.preventDefault();

    const container = $(this).closest('.resend-fields');
    const url = $(this).data('url') || this.href;
    const method = $(this).data('requestMethod') || 'POST';
    const data = container.find('input').serializeArray();
    const request = {
      data,
      action(overrideData) {
        const body = overrideData || this.data;
        $.ajax(url, {
          data: body,
          method,
          dataType: 'html'
        }).done((html) => {
          if (this.onSuccess) {
            this.onSuccess();
          }

          const resendFields = $(html).find('.resend-fields');
          if (resendFields.length > 0) {
            container.replaceWith(resendFields);
          } else {
            container.html(html);
          }
        }).fail((xhr) => {
          if (this.handleError && this.handleError(xhr)) {
            return;
          }

          if (xhr.status === 401) {
            alert('您当前登录用户已超时，请重试!');
            location.href = location.href;
          }
        });
      }
    };

    requestWithCaptcha(request);
  });
});
