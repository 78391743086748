import _ from 'lodash';
import $ from 'jquery';

import URI from 'urijs';
import 'urijs/src/URITemplate';

const handleFormUrlTemplate = function() {
  const $this = $(this);
  if ($this.data('url-expanded')) {
    return true;
  }

  $this.data('url-expanded', true);

  $this.find('.expand-value').remove();

  const action = $this.attr('action');
  const expandValues = {};
  _.each($this.find('.expand-item'), function(item) {
    $(item).prop('disabled', true);
    expandValues[item.name] = item.value;
  });
  const uri = URI.expand(action, expandValues);
  _.forEach(uri.query(true), function(value, name) {
    $('<input />', {
      value,
      name,
      type: 'hidden',
      class: 'expand-value'
    }).appendTo($this);
  });
  $this.attr('action', uri.query('').href());

  return true;
};

$(document).on('content:loaded', function(event) {
  $(event.target).find('[data-form]').on('click', function() {
    const $this = $(this);
    const formSelector = $this.data('form');
    $(formSelector)
      .attr('action', $this.data('href'))
      .removeData('url-expanded');
  });

  $(event.target).on('submit ajax:before', '.url-template', handleFormUrlTemplate);
});
