import $ from 'jquery';
import _ from 'lodash';

$.fn.autoClickOnce = function() {
  $(this).find('.auto-click-once').each(function() {
    $(this).removeClass('auto-click-once');
    const element = this;
    const delay = $(element).data('delay');
    if (delay > 0) {
      setTimeout(function() {
        if (document.contains(element)) {
          element.click()
        }
      }, delay);
    } else {
      element.click();
    }
  });
};

$(document).on('content:ready', function(event) {
  $(event.target).autoClickOnce();
});

$(document).on('click', '.delegate', function(event) {
  event.preventDefault();
  const target = $(this).data('target');
  let $target = null;
  if (_.startsWith(target, '#')) {
    $target = $(target);
  } else {
    $target = $(this).closest(target);
  }
  $target.click();
});
