import $ from 'jquery';

$(document).on('show.bs.modal', function(event) {
  const $trigger = $(event.relatedTarget);
  const url = $trigger.data('url');
  if (url) {
    const $modal = $(event.target);
    if ($modal.data('reset-content')) {
      const content = $modal.data('reset-content')
      $modal.find('.modal-content').html(content)
    } else {
      const content = $modal.find('.modal-content').html()
      $modal.data('reset-content', content)
    }
    $modal.find('.modal-content').load(url, function() {
      $modal.trigger('content:loading');
      $modal.trigger('content:loaded');
      setTimeout(function() {
        $modal.trigger('content:ready');
      }, 0)
    });
  }
});
